export const sliderData = [
    {
        image: "/main-slider/01.jpg",
        heading: "BOLONIA 26/11/2024",
        desc: "Omar Montes Eurotrip"
    }, 
    {
        image: "/main-slider/02.jpg",
        heading: "BRATISLAVA 12/10/2024",
        desc: "The Biggest Erasmus Festival en Bratislava con Selecta"
    }, 
    {
        image: "/main-slider/03.jpg",
        heading: "BRATISLAVA 12/10/2023",
        desc: "The Biggest Erasmus Festival en Bratislava con Kiko Rivera"
    }, 
    {
        image: "/main-slider/04.jpg",
        heading: "BRATISLAVA 12/10/2023",
        desc: "The Biggest Erasmus Festival en Bratislava"
    }, 
    {
        image: "/main-slider/05.jpg",
        heading: "BRUSELAS 27/11/2024",
        desc: "Omar Montes Eurotrip"
    }, 
    {
        image: "/main-slider/06.jpg",
        heading: "Bratislava 12/10/2024",
        desc: "The Biggest Erasmus Festival en Bratislava con Barce"
    }, 


]