
import React from "react";
import "../Styles/main.css";
import CountriesCard from "./CountriesCards";
import Footer from "./Footer";

function CountriesGallery() {
  return (
    <div>
      <CountriesCard />
      <Footer />
    </div>
    
  );
}

export default CountriesGallery;