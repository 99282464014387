export const pastEvents = [
  {

    image: '../past-22-23/MUNICH.jpg',
    pastEvent:true,
    },
  {

    image: '../past-22-23/BRUUSELS.jpg',
    pastEvent:true,
    },
  {

    image: '../past-22-23/MARTINI.jpg',
    pastEvent:true,
    },
    {

      image: '../past-22-23/mex.jpg',
      pastEvent:true,
      },
  {

    image: '../past-22-23/fut.jpg',
    pastEvent:true,
    },
  {

    image: '../past-22-23/PORTO20.jpg',
    pastEvent:true,
    },
    {
  
      image: '../past-22-23/sex.jpg',
      pastEvent:true,
      },
    {

    image: '../past-22-23/BRATIS13APRIL.jpg',
    pastEvent:true,
    },
    {
 
      image: '../past-22-23/24MAR.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/23MAR.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/23MARZ.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/21MAR.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/16MAR.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/14MAR.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/10MAR.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/06MAR.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/2MARZ.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/02MAR.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/01FEB.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/02FEB.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/04FEB.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/08FEB (2).jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/08FEB.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/16FEB.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/21FEBRE.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/22FEB (2).jpg',
      pastEvent:true,
    },
    {
 
      image: '../past-22-23/22FEB.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/23FEB.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/23FEBRE.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/25FEB.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/12ENE.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/12ENER.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/14ENE.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/18ENE.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/19ENER.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/19ENE.jpg',
      pastEvent:true,
    },
    {
 
      image: '../past-22-23/25ENER.jpg',
      pastEvent:true,
    },
    {
 
      image: '../past-22-23/25ENE.jpg',
      pastEvent:true,
    },
    {

      image: '../past-22-23/28ENE.jpg',
      pastEvent:true,
    },




  ];